import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, useMemo } from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, IconButton, } from '@mui/material';
import { LoadingView } from '../../../components/loading-view';
import { formatXml } from '../../../utils/formatters';
import { CopyCodeButton } from './copy-code-button';
import { tsr } from '../../../utils/tsr';
export const SourceDetailDialog = ({ selectedSourceKey, onClose, }) => {
    const isOpen = !!selectedSourceKey;
    return (_jsxs(Dialog, { fullWidth: true, closeAfterTransition: true, maxWidth: "lg", open: isOpen, onClose: onClose, TransitionComponent: Fade, children: [_jsxs(DialogTitle, { borderBottom: 1, borderColor: "grey.300", children: ["Source", _jsx(IconButton, { "aria-label": "close", onClick: onClose, sx: {
                            position: 'absolute',
                            right: 8,
                            top: 14,
                        }, children: _jsx(Close, {}) })] }), _jsx(Suspense, { fallback: _jsx(DialogContent, { children: _jsx(LoadingView, {}) }), children: isOpen && (_jsx(SourceDetailDialogContent, { selectedSourceKey: selectedSourceKey })) })] }));
};
const isJsonSource = (source) => {
    const withoutSpace = source.trim();
    return ((withoutSpace.startsWith('{') && withoutSpace.endsWith('}')) ||
        (withoutSpace.startsWith('[') && withoutSpace.startsWith(']')));
};
function SourceDetailDialogContent({ selectedSourceKey, }) {
    const { data } = tsr.adminIngestLogs.source.useSuspenseQuery({
        queryData: {
            query: { key: selectedSourceKey },
        },
        queryKey: ['dataLogSource', selectedSourceKey],
    });
    const source = data.body.source;
    const formattedSource = useMemo(() => {
        if (!source) {
            return undefined;
        }
        if (isJsonSource(source)) {
            return JSON.stringify(JSON.parse(source), null, 4);
        }
        return formatXml(source);
    }, [source]);
    return (_jsxs(_Fragment, { children: [_jsx(DialogContent, { children: formattedSource && (_jsx("pre", { children: _jsx(DialogContentText, { variant: "body2", component: "code", children: formattedSource }) })) }), _jsx(DialogActions, { children: source && _jsx(CopyCodeButton, { code: source }) })] }));
}
