import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { mustEnv } from '../../utils/env';
import { getLoginRedirectUri } from '../../utils/fallback';
import { AuthContext } from './context';
import { deleteLocalAccessToken, getLocalAccessToken, setLocalAccessToken, } from './local-token-utils';
import { tsr } from '../../utils/tsr';
const cognitoDomain = mustEnv('VITE_COGNITO_DOMAIN');
const cognitoClientId = mustEnv('VITE_COGNITO_CLIENT_ID');
const loginRedirect = getLoginRedirectUri();
export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!getLocalAccessToken());
    const redirectToSignIn = useCallback(() => {
        window.location.href = `${cognitoDomain}/login?client_id=${cognitoClientId}&response_type=code&scope=openid&redirect_uri=${loginRedirect}`;
    }, []);
    const signInWithAuthCode = useCallback(async (code) => {
        const result = await tsr.adminUsers.login.mutate({
            body: { code, redirect: loginRedirect },
        });
        if (result.status !== 200) {
            throw result;
        }
        setLocalAccessToken(result.body.token);
        setIsLoggedIn(true);
    }, []);
    const signOut = useCallback((queryClient) => {
        deleteLocalAccessToken();
        setIsLoggedIn(false);
        queryClient.clear();
    }, []);
    return (_jsx(AuthContext.Provider, { value: {
            isLoggedIn,
            redirectToSignIn,
            signInWithAuthCode,
            signOut,
        }, children: children }));
};
