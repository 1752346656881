const permissions = [
  'admin:analytics:read',
  'admin:broadcast:create',
  'admin:broadcast:read',
  'admin:broadcastTemplate:create',
  'admin:broadcastTemplate:read',
  'admin:broadcastTemplate:delete',
  'admin:logs:read',
  'admin:point:read',
  'admin:settings:feeds:read',
  'admin:settings:feeds:update',
  'admin:settings:rules:read',
  'admin:user:create',
  'admin:user:read',
  'admin:user:update',
] as const;
type Permission = (typeof permissions)[number];
type Permissions = Permission[];

export type { Permission, Permissions };
export { permissions };
