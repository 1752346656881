import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
import { ButtonBase, Stack, Typography } from '@mui/material';
import { Suspense } from 'react';
import { useAuth } from '../../core/auth';
import { Container } from '../container';
import { useQueryClient } from '@tanstack/react-query';
import { useUserDetails } from '../../core/auth/hooks/use-user-details';
export const HEADER_HEIGHT = 94;
export const Header = () => {
    const queryClient = useQueryClient();
    const { signOut, isLoggedIn } = useAuth();
    return (_jsx("header", { className: css({
            height: HEADER_HEIGHT,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#2957B0',
            overflow: 'hidden',
        }), children: _jsxs(Container, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Typography, { variant: "h5", color: "white", children: "ADMIN DASHBOARD" }), _jsxs(Stack, { direction: "row", spacing: 2, alignItems: "center", children: [isLoggedIn && (_jsx(Suspense, { children: _jsx(LoggedInEmail, {}) })), _jsx(ButtonBase, { sx: { color: 'white' }, onClick: () => signOut(queryClient), children: "LOG OUT" })] })] }) }));
};
const LoggedInEmail = () => {
    const { email } = useUserDetails();
    return (_jsxs(Stack, { direction: "row", spacing: 2, sx: { display: { xs: 'none', md: 'flex' } }, children: [_jsx(Typography, { color: "white", children: email }), _jsx(Typography, { color: "white", children: "|" })] }));
};
