import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
  BadRequestErrorSchema,
  commonResponses,
  routeMetadata,
  StateSchema,
} from './common';

const c = initContract();

// Transforms

const TransformConfigPresentationSchema = z.object({
  type: z.string(),
  definition: z.string(),
});

type TransformConfigPresentation = z.infer<
  typeof TransformConfigPresentationSchema
>;

// Ingest Rules for Presentation

enum IngestRuleTypeEnum {
  jsonpath = 'jsonpath',
  xpath = 'xpath',
  constant = 'constant',
  join = 'join',
  reference = 'reference',
  custom = 'custom',
}

const IngestRulePresentationBaseSchema = z.object({
  transforms: z.array(TransformConfigPresentationSchema).optional(),
  valueType: z.enum(['string', 'array']),
});

const JsonPathRuleSchema = IngestRulePresentationBaseSchema.extend({
  type: z.literal(IngestRuleTypeEnum.jsonpath),
  path: z.string(),
});

const XPathRuleSchema = IngestRulePresentationBaseSchema.extend({
  type: z.literal(IngestRuleTypeEnum.xpath),
  path: z.string(),
});

const ConstantRuleSchema = IngestRulePresentationBaseSchema.extend({
  type: z.literal(IngestRuleTypeEnum.constant),
  constant: z.union([z.string(), z.number(), z.boolean()]),
});

const JoinRuleSchema = IngestRulePresentationBaseSchema.extend({
  type: z.literal(IngestRuleTypeEnum.join),
  separator: z.string(),
  rules: z.string(),
});

const ReferenceRuleSchema = IngestRulePresentationBaseSchema.extend({
  type: z.literal(IngestRuleTypeEnum.reference),
  definition: z.string(),
});

const CustomRuleSchema = IngestRulePresentationBaseSchema.extend({
  type: z.literal(IngestRuleTypeEnum.custom),
  definition: z.string(),
});

const IngestRulePresentationSchema = z.union([
  JsonPathRuleSchema,
  XPathRuleSchema,
  ConstantRuleSchema,
  JoinRuleSchema,
  ReferenceRuleSchema,
  CustomRuleSchema,
]);

type IngestRulePresentation = z.infer<typeof IngestRulePresentationSchema>;

// Ingest Rule Presentation Map

type IngestRulePresentationMap = {
  [key: string]: IngestRulePresentation | IngestRulePresentationMap;
};
const IngestRulePresentationMapSchema: z.ZodType<IngestRulePresentationMap> =
  z.record(
    z.string(),
    z.lazy(() =>
      z.union([IngestRulePresentationSchema, IngestRulePresentationMapSchema]),
    ),
  );

// Collections

function createCollectionIngestRulesPresentationSchema<T extends z.ZodTypeAny>(
  collectionMapSchema: T,
) {
  return z.object({
    name: z.string(),
    website: z
      .object({
        name: z.string(),
        url: z.string(),
      })
      .optional(),
    feedUrl: z.string(),
    feedType: z.enum(['xml', 'json']),
    collectionMap: collectionMapSchema,
    itemsMap: IngestRulePresentationMapSchema,
    areaItemsMap: IngestRulePresentationMapSchema.optional(),
  });
}

const CollectionIngestRulesPresentationSchema =
  createCollectionIngestRulesPresentationSchema(
    IngestRulePresentationMapSchema,
  );

type CollectionIngestRulesPresentation = z.infer<
  typeof CollectionIngestRulesPresentationSchema
>;

// More narrow type of "Collection Ingest Rules" specifically used by incident ingestion
const IncidentCollectionIngestRulesPresentationSchema =
  createCollectionIngestRulesPresentationSchema(
    z.object({
      incidents: IngestRulePresentationSchema,
      updated: IngestRulePresentationSchema,
    }),
  );

type IncidentCollectionIngestRulesPresentation = z.infer<
  typeof IncidentCollectionIngestRulesPresentationSchema
>;

// Router

const adminTransformRulesContract = c.router(
  {
    get: {
      path: '/admin/settings/rules/:state',
      pathParams: z.object({
        state: StateSchema,
      }),
      method: 'GET',
      responses: {
        200: z.record(z.string(), CollectionIngestRulesPresentationSchema),
        400: BadRequestErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:settings:rules:read'],
      }),
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      tags: ['Admin/Transform Rules'],
    }),
  },
);

export { IngestRuleTypeEnum, adminTransformRulesContract };
export type {
  IngestRulePresentation,
  IngestRulePresentationMap,
  CollectionIngestRulesPresentation,
  IncidentCollectionIngestRulesPresentation,
  TransformConfigPresentation,
};
