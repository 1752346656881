import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import { orderBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { NotificationCountTooltip } from '../../../components/notification-count-tooltip';
import { formatDate, formatNumber } from '../../../utils/formatters';
import { tsr } from '../../../utils/tsr';
export const BroadcastHistory = () => {
    const navigate = useNavigate();
    const { data } = tsr.adminBroadcasts.list.useSuspenseQuery({
        queryData: { query: {} },
        queryKey: ['broadcasts'],
    });
    const items = orderBy(data.body.items, 'createdAt', 'desc');
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { style: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Area" }), _jsx(TableCell, { children: "Title" }), _jsx(TableCell, { children: "Message" }), _jsx(TableCell, { children: "Author" }), _jsx(TableCell, { children: "Sent" }), _jsx(TableCell, { children: "Notifications" })] }) }), _jsx(TableBody, { children: items.map((row) => {
                        const createdAt = new Date(row.createdAt * 1000);
                        return (_jsxs(TableRow, { hover: true, onClick: () => navigate(`/broadcasts/${row.id}`), children: [_jsx(TableCell, { children: _jsx(Typography, { variant: "body2", fontWeight: "bold", children: row.name }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: row.title }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", whiteSpace: "pre-line", children: row.message }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: row.createdByUserName }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", noWrap: true, children: formatDate(createdAt) }) }), _jsx(TableCell, { children: row.deviceCount !== undefined && (_jsxs(Stack, { direction: "row", alignItems: "center", spacing: 0.5, children: [_jsx(Typography, { variant: "body2", children: formatNumber(row.deviceCount) }), _jsx(NotificationCountTooltip, { createdAt: createdAt })] })) })] }, row.id));
                    }) })] }) }));
};
