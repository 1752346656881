import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingView } from '../../../components/loading-view';
import { LogLevelLabel } from '../../../components/log-level-label';
import { formatDate } from '../../../utils/formatters';
import { tsr } from '../../../utils/tsr';
export const DataLogTable = () => {
    const dataLogsQuery = tsr.adminIngestLogs.list.useSuspenseQuery({
        queryKey: ['dataLogs'],
    });
    const navigate = useNavigate();
    if (dataLogsQuery.isLoading) {
        return _jsx(LoadingView, { paddingTop: 8 });
    }
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { sx: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Jurisdiction" }), _jsx(TableCell, { children: "Type" }), _jsx(TableCell, { align: "right", children: "Created at (GMT+11)" }), _jsx(TableCell, { align: "right", children: "Status" })] }) }), _jsx(TableBody, { children: dataLogsQuery.data.body.map((row, index) => (_jsxs(TableRow, { hover: true, sx: { cursor: 'pointer' }, onClick: () => navigate(`${row.name.toLowerCase()}/${row.type
                            .replaceAll(' ', '-')
                            .toLowerCase()}`), children: [_jsx(TableCell, { component: "th", scope: "row", children: _jsx(Typography, { fontWeight: "bold", children: row.name }) }), _jsx(TableCell, { children: row.type }), _jsx(TableCell, { align: "right", children: formatDate(row.createdAt) }), _jsx(TableCell, { align: "right", children: row.level && _jsx(LogLevelLabel, { level: row.level }) })] }, `${row.name}-${index}`))) })] }) }));
};
