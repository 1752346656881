import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { Container } from '../../components/container';
import { BroadcastAreasList } from './components/broadcast-areas-list';
import { LoadingView } from '../../components/loading-view';
export const BroadcastAreas = () => {
    return (_jsx(Container, { children: _jsxs(Stack, { paddingY: 4, spacing: 3, children: [_jsx(Box, { children: _jsx(Button, { variant: "contained", startIcon: _jsx(Add, {}), component: Link, to: "create", disabled: true, children: "Create broadcast area" }) }), _jsx(Suspense, { fallback: _jsx(LoadingView, { height: "100%" }), children: _jsx(BroadcastAreasList, {}) })] }) }));
};
