import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useMemo } from 'react';
import { Alert, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BroadcastAreaMap, BroadcastAreaPolygon, } from '../../components/broadcast-area-map';
import { Container } from '../../components/container';
import { LabelAndValue } from '../../components/label-and-value';
import { LoadingView } from '../../components/loading-view';
import { NavBack } from '../../components/nav';
import { NotificationCountTooltip } from '../../components/notification-count-tooltip';
import { formatDate, formatNumber } from '../../utils/formatters';
import { getCenterOfPolygons, parsePolygon } from '../../utils/geo';
import { useFilteredBroadcastTemplates } from '../create-broadcast/components/shared';
import { tsr } from '../../utils/tsr';
export const Broadcast = ({ id }) => {
    const { data } = tsr.adminBroadcasts.get.useSuspenseQuery({
        queryData: { params: { id } },
        queryKey: ['broadcast', id],
    });
    const { templates } = useFilteredBroadcastTemplates(data?.body.areaTemplateIds);
    const polygons = useMemo(() => {
        if (data?.body.polygon) {
            return [parsePolygon(data.body.polygon)];
        }
        if (templates?.length) {
            return templates.map((template) => parsePolygon(template.polygon));
        }
        return undefined;
    }, [data?.body.polygon, templates]);
    const polygonsCenter = useMemo(() => {
        if (!polygons) {
            return undefined;
        }
        if (polygons.length === 1) {
            return polygons[0]?.center;
        }
        return getCenterOfPolygons(polygons);
    }, [polygons]);
    const area = useMemo(() => (_jsxs(_Fragment, { children: [_jsx(Typography, { color: "GrayText", children: data?.body.name }), templates && (_jsx(Box, { mt: 1, ml: 2, children: templates.map((template) => (_jsx(Typography, { color: "GrayText", children: template.name }))) }))] })), [data, templates]);
    return (_jsx(Container, { children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, md: 5, children: _jsxs(Stack, { spacing: 3, paddingY: 3, height: "100%", children: [_jsx(Alert, { severity: "info", children: "Broadcast history details" }), _jsx(_Fragment, { children: _jsxs(Stack, { spacing: 3, height: "100%", divider: _jsx(Divider, {}), children: [data.body.name && (_jsx(LabelAndValue, { label: "Notification Area", value: area })), _jsx(LabelAndValue, { label: "Title", value: data.body.title }), _jsx(LabelAndValue, { label: "Message", value: data.body.message }), _jsx(LabelAndValue, { label: "URL for more information", value: data.body.moreInformationUrl || _jsx("i", { children: "Not entered" }) }), data.body.deviceCount !== undefined && (_jsx(LabelAndValue, { label: "Notifications", value: _jsxs(Stack, { component: "span", direction: "row", alignItems: "center", spacing: 0.5, children: [_jsx(Typography, { variant: "inherit", children: formatNumber(data.body.deviceCount) }), _jsx(NotificationCountTooltip, { createdAt: new Date(data.body.createdAt * 1000) })] }) })), _jsx(LabelAndValue, { label: "Sent", value: formatDate(data.body.createdAt * 1000) })] }) })] }) }), _jsx(Grid, { item: true, xs: 12, md: 7, children: _jsx(BroadcastAreaMap, { center: polygonsCenter, children: polygons?.map((polygon) => (_jsx(BroadcastAreaPolygon, { paths: polygon?.paths }))) }) })] }) }));
};
export const ViewBroadcast = () => {
    const { id } = useParams();
    return (_jsxs(_Fragment, { children: [_jsx(NavBack, {}), _jsx(Suspense, { fallback: _jsx(LoadingView, { height: "100vh" }), children: _jsx(Broadcast, { id: id }) })] }));
};
