import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { SnackbarContext } from './context';
export const SnackbarProvider = ({ children }) => {
    const [snack, setSnack] = useState();
    const handleClose = useCallback(() => {
        setSnack(undefined);
    }, []);
    return (_jsxs(SnackbarContext.Provider, { value: {
            showSnackbar: (message, severity) => setSnack({ message, severity }),
        }, children: [children, _jsx(Snackbar, { open: !!snack, autoHideDuration: 5000, onClose: handleClose, anchorOrigin: { vertical: 'top', horizontal: 'right' }, children: _jsx(Alert, { severity: snack?.severity, sx: { width: '100%' }, children: snack?.message }) })] }));
};
