import { jsx as _jsx } from "react/jsx-runtime";
import { IngestLogMessageLevelEnum } from '@hazards/api-contract';
import { Typography } from '@mui/material';
export const LogLevelLabel = ({ level, ...props }) => (_jsx(Typography, { ...props, color: mapLevelToColor[level], children: mapLevelToLabel[level] }));
const mapLevelToLabel = {
    [IngestLogMessageLevelEnum.INFO]: 'Information',
    [IngestLogMessageLevelEnum.WARNING]: 'Warning',
    [IngestLogMessageLevelEnum.ERROR]: 'Error',
};
const mapLevelToColor = {
    [IngestLogMessageLevelEnum.INFO]: '#67CE84',
    [IngestLogMessageLevelEnum.WARNING]: '#FF7800',
    [IngestLogMessageLevelEnum.ERROR]: '#D6001C',
};
