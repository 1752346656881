import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Container } from '../../components/container';
import { NavBack } from '../../components/nav';
import { SuccessWidget } from '../../components/success-widget';
import { validateField } from '../../utils/form';
import { ButtonContainer } from '../create-broadcast/components/shared';
import { getTsrErrorMessage, tsr } from '../../utils/tsr';
import { adminRoleToLabel } from '../users/components/labels';
const inviteUserFormSchema = z.object({
    email: z.string().email('Must be a valid email address'),
    role: z.enum(['admin', 'readonly']),
});
export const InviteUser = () => {
    const [completed, setCompleted] = useState(false);
    const { mutateAsync, error } = tsr.adminUsers.create.useMutation();
    const formMethods = useForm({
        mode: 'all',
        resolver: zodResolver(inviteUserFormSchema),
    });
    const { register, handleSubmit, formState: { errors, isSubmitting }, control, } = formMethods;
    const onSubmit = useCallback(async (formValues) => {
        const res = await mutateAsync({
            body: { email: formValues.email, role: formValues.role },
        });
        if (res.status === 200) {
            setCompleted(true);
        }
    }, []);
    if (completed) {
        return (_jsx(SuccessWidget, { text: "User has been sent an invitation email", returnTo: "/users" }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(NavBack, {}), _jsx(Container, { children: _jsxs(Stack, { paddingTop: 3, children: [_jsx(Typography, { variant: "h5", children: "Invite user" }), _jsx(FormProvider, { ...formMethods, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(Grid, { container: true, spacing: 3, children: _jsx(Grid, { item: true, xs: 12, md: 5, children: _jsxs(Stack, { spacing: 3, paddingY: 3, children: [error && (_jsx(Alert, { severity: "error", children: getTsrErrorMessage(error) })), _jsx(TextField, { ...register('email'), label: "Email", ...validateField(errors, 'email') }), _jsx(Controller, { name: "role", control: control, rules: { required: 'Please select a role' }, render: ({ field }) => (_jsxs(FormControl, { fullWidth: true, margin: "normal", error: !!errors.role, children: [_jsx(InputLabel, { id: "role-select-label", children: "Role" }), _jsxs(Select, { ...field, labelId: "role-select-label", id: "role-select", label: "Role", value: field.value ?? '', children: [_jsx(MenuItem, { value: 'admin', children: adminRoleToLabel.admin }), _jsx(MenuItem, { value: 'readonly', children: adminRoleToLabel.readonly })] }), errors.role && (_jsx(FormHelperText, { children: errors.role.message }))] })) }), _jsx(ButtonContainer, { children: _jsx(LoadingButton, { type: "submit", variant: "contained", loading: isSubmitting, children: "Send invitation" }) })] }) }) }) }) })] }) })] }));
};
