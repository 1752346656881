import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { FormControl, Grid, InputLabel, Link, MenuItem, Select, Stack, Typography, } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Container } from '../../components/container';
import { NavBack } from '../../components/nav';
import { DataLogMessagesTable, LogLevelFilter, } from './components/data-log-messages-table';
import { SourceDetailDialog } from './components/source-detail-dialog';
import { tsr } from '../../utils/tsr';
import { LoadingView } from '../../components/loading-view';
export const DataLogMessages = () => {
    const [filter, setFilter] = useState(LogLevelFilter.ALL);
    const [selectedSourceKey, setSelectedSourceKey] = useState();
    const { isLoading, data } = useDataLogDetails();
    const sources = data?.sources ?? {};
    const dataSources = Object.keys(sources);
    return (_jsxs(_Fragment, { children: [_jsx(NavBack, {}), _jsx(Container, { children: _jsxs(Stack, { paddingY: 2, spacing: 3, children: [_jsxs(Grid, { container: true, justifyContent: "space-between", spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, md: 10, children: _jsxs(Stack, { spacing: 3, children: [_jsx(Typography, { variant: "h5", children: data?.name }), !!dataSources.length && (_jsxs(Stack, { spacing: 1, children: [_jsx(Typography, { variant: "subtitle1", children: "Sources" }), _jsx(Stack, { spacing: 1, bgcolor: "#FAFAFA", alignSelf: "flex-start", paddingX: 2, paddingY: 1, borderRadius: 3, children: dataSources.map((source) => (_jsx(Link, { onClick: () => setSelectedSourceKey(sources[source]), sx: { cursor: 'pointer' }, children: source }, sources[source]))) })] }))] }) }), _jsx(Grid, { item: true, xs: 12, md: 2, children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "log-level-select-label", children: "Log level" }), _jsxs(Select, { labelId: "log-level-select-label", value: filter, label: "Log level", onChange: (e) => setFilter(e.target.value), children: [_jsx(MenuItem, { value: LogLevelFilter.ALL, children: logLevelFilterToLabel[LogLevelFilter.ALL] }), _jsx(MenuItem, { value: LogLevelFilter.INFO, children: logLevelFilterToLabel[LogLevelFilter.INFO] }), _jsx(MenuItem, { value: LogLevelFilter.WARNING, children: logLevelFilterToLabel[LogLevelFilter.WARNING] }), _jsx(MenuItem, { value: LogLevelFilter.ERROR, children: logLevelFilterToLabel[LogLevelFilter.ERROR] })] })] }) })] }), isLoading && _jsx(LoadingView, { paddingTop: 8 }), data && _jsx(DataLogMessagesTable, { data: data, filter: filter })] }) }), _jsx(SourceDetailDialog, { selectedSourceKey: selectedSourceKey, onClose: () => setSelectedSourceKey(undefined) })] }));
};
const useDataLogDetails = () => {
    const { name, type } = useParams();
    const dataLogsQuery = tsr.adminIngestLogs.list.useQuery({
        queryKey: ['dataLogs'],
        throwOnError: true,
    });
    const data = useMemo(() => dataLogsQuery.data?.body.find((d) => d.name.toLowerCase() === name?.toLowerCase() &&
        d.type.replaceAll(' ', '-').toLowerCase() === type), [name, dataLogsQuery.data, type]);
    return {
        data,
        isLoading: dataLogsQuery.isLoading,
    };
};
const logLevelFilterToLabel = {
    [LogLevelFilter.ALL]: 'All',
    [LogLevelFilter.INFO]: 'Information',
    [LogLevelFilter.WARNING]: 'Warning',
    [LogLevelFilter.ERROR]: 'Error',
};
