import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, } from '@mui/material';
import { capitalize, isEqual } from 'lodash';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ButtonContainer } from '../../create-broadcast/components/shared';
import { tsr } from '../../../utils/tsr';
import { useHasPermission } from '../../../core/auth/hooks/use-has-permission';
const FEED_SOURCES = {
    live: 'Live',
    mock: 'S3 Mock',
    custom: 'Custom',
};
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export const FeedSettingsTable = () => {
    const canUpdateFeedSettings = useHasPermission('admin:settings:feeds:update');
    const { data, refetch } = tsr.adminMockFeedSettings.get.useSuspenseQuery({
        queryKey: ['feed-settings'],
    });
    const { mutateAsync } = tsr.adminMockFeedSettings.update.useMutation();
    const [feedSettings, setFeedSettings] = useState([]);
    const [customModalIndex, setCustomModalIndex] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        if (data) {
            setFeedSettings(data.body);
        }
    }, [data]);
    const save = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await mutateAsync({ body: { feedSettings } });
            await refetch();
        }
        finally {
            setIsSubmitting(false);
        }
    }, [feedSettings, refetch]);
    const disableInteraction = import.meta.env.VITE_STAGE.includes('prod') || !canUpdateFeedSettings;
    const setFeedSettingsWithIndex = (idx, updatedFeedSetting) => {
        setFeedSettings([
            ...feedSettings.slice(0, idx),
            updatedFeedSetting,
            ...feedSettings.slice(idx + 1),
        ]);
    };
    return (_jsxs(_Fragment, { children: [_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { style: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Jurisdiction" }), _jsx(TableCell, { children: "Type" }), _jsx(TableCell, { children: "Feed" }), _jsx(TableCell, { children: "Rules" })] }) }), _jsx(TableBody, { children: feedSettings?.map((feedSetting, idx) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: feedSetting.jurisdiction.toUpperCase() }), _jsx(TableCell, { children: capitalize(feedSetting.type) }), _jsx(TableCell, { children: _jsx(Select, { value: feedSetting.source, onSelect: (v) => console.log(v), onChange: (e) => {
                                                const newSource = e.target.value;
                                                if (newSource !== 'custom') {
                                                    setFeedSettingsWithIndex(idx, {
                                                        ...feedSetting,
                                                        source: newSource,
                                                    });
                                                }
                                            }, size: "small", disabled: disableInteraction, children: Object.entries(FEED_SOURCES).map(([feedSource, label]) => (_jsx(MenuItem, { value: feedSource, onClick: () => {
                                                    if (feedSource === 'custom') {
                                                        setCustomModalIndex(idx);
                                                    }
                                                }, children: label }, feedSource))) }) }), _jsx(TableCell, { children: _jsx(Button, { variant: "contained", component: Link, to: `/settings/rules/${feedSetting.jurisdiction}`, children: "View" }) })] }, `${feedSetting.jurisdiction}${feedSetting.type}`))) })] }) }), _jsx(ButtonContainer, { children: _jsx(LoadingButton, { variant: "contained", loading: isSubmitting, onClick: save, disabled: disableInteraction, children: "Save" }) }), _jsx(Modal, { open: customModalIndex !== null, onClose: () => setCustomModalIndex(null), "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", children: _jsx(Box, { sx: modalStyle, children: customModalIndex !== null && (_jsx(CustomFeedSettingsForm, { feedSettings: feedSettings[customModalIndex].feedSettings, onSubmit: (custom) => {
                            if (!isEqual(custom, feedSettings[customModalIndex].feedSettings)) {
                                setFeedSettingsWithIndex(customModalIndex, {
                                    ...feedSettings[customModalIndex],
                                    source: 'custom',
                                    custom,
                                });
                            }
                            setCustomModalIndex(null);
                        } })) }) })] }));
};
function CustomFeedSettingsForm({ feedSettings, onSubmit, }) {
    const { register, handleSubmit } = useForm({ defaultValues: feedSettings });
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx("h4", { children: "Custom Feed Settings" }), Object.keys(feedSettings).map((key) => (_jsx(TextField, { fullWidth: true, label: key, variant: "standard", margin: "normal", ...register(key) }, key))), _jsx("br", {}), _jsx("br", {}), _jsx(Button, { type: "submit", variant: "contained", children: "Set" })] }));
}
