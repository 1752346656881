import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
export const ErrorFallback = ({ error, resetErrorBoundary, }) => {
    const navigate = useNavigate();
    const handleReset = () => {
        resetErrorBoundary();
        navigate('/', { replace: true });
    };
    return (_jsxs("div", { role: "alert", children: [_jsx("h2", { children: "Oops! Something went wrong." }), _jsx("pre", { children: error.message }), _jsx("button", { onClick: handleReset, children: "Try again" })] }));
};
