const ACCESS_TOKEN_KEY = 'accessToken';
export function getLocalAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}
export function setLocalAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}
export async function deleteLocalAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
}
