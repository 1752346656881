import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { tsr } from '../../../utils/tsr';
import { Box, CircularProgress, MenuItem, Select } from '@mui/material';
import { adminRoleToLabel } from './labels';
import { useSnackbar } from '../../../components/snackbar';
import { useHasPermission } from '../../../core/auth/hooks/use-has-permission';
export function RoleSelect({ user, onChange }) {
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const canUpdateUser = useHasPermission('admin:user:update');
    const { mutateAsync: updateUser } = tsr.adminUsers.update.useMutation();
    const update = useCallback(async (role) => {
        setLoading(true);
        try {
            await updateUser({ params: { id: user.id }, body: { role } });
            await onChange();
            showSnackbar(`${user.email} has been updated`);
        }
        finally {
            setLoading(false);
        }
    }, [onChange, showSnackbar, user]);
    return (_jsxs(Box, { position: "relative", children: [_jsxs(Select, { value: user.role, size: "small", onChange: (e) => update(e.target.value), disabled: user.role === 'superadmin' || !canUpdateUser, children: [_jsx(MenuItem, { value: 'superadmin', disabled: true, children: adminRoleToLabel.superadmin }), _jsx(MenuItem, { value: 'admin', children: adminRoleToLabel.admin }), _jsx(MenuItem, { value: 'readonly', children: adminRoleToLabel.readonly })] }), loading && (_jsx(CircularProgress, { size: 24, sx: {
                    position: 'absolute',
                    marginLeft: '8px',
                    marginTop: '8px',
                } }))] }));
}
