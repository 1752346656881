import { jsx as _jsx } from "react/jsx-runtime";
import { GenericRulesTable } from './generic-rules-table';
import { IncidentsRulesTable } from './incidents-rules-table';
export function isIncidentCollectionIngestRule(value) {
    return 'incidents' in value.collectionMap && 'updated' in value.collectionMap;
}
export const RulesTable = ({ data, }) => {
    if (isIncidentCollectionIngestRule(data)) {
        return _jsx(IncidentsRulesTable, { data: data });
    }
    return _jsx(GenericRulesTable, { data: data });
};
