import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ApiErrorSchema,
  BadRequestErrorSchema,
  commonResponses,
  routeMetadata,
} from './common';

const c = initContract();

const BroadcastSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  title: z.string().max(200).optional(),
  message: z.string().max(1200),
  moreInformationUrl: z.string().optional(),
  createdByUserId: z.string(),
  createdByUserName: z.string().optional(),
  createdAt: z.number(),
  deviceCount: z.number().optional(),
  coordinates: z
    .array(z.array(z.number()).min(2).max(2))
    .min(3)
    .max(120)
    .optional(),
  polygon: z.string().optional(),
  areaTemplateIds: z.array(z.string()).optional(),
});

const createOmits = {
  id: true,
  createdByUserId: true,
  createdByUserName: true,
  createdAt: true,
  deviceCount: true,
  polygon: true,
} as const;
const createRequireds = {
  // migrated this behaviour from old code. New broadcasts require it, could the database have old broadcasts without it?
  name: true,
  title: true,
} as const;
const BroadcastInputSchema = z.union([
  BroadcastSchema.extend({ type: z.literal('ALL_DEVICES') })
    .omit({ ...createOmits })
    .omit({ coordinates: true, areaTemplateIds: true })
    .required(createRequireds)
    .strict(),
  BroadcastSchema.extend({ type: z.literal('DEVICES_IN_AREA') })
    .omit(createOmits)
    .omit({ areaTemplateIds: true })
    .required(createRequireds)
    .required({ coordinates: true })
    .strict(),
  BroadcastSchema.extend({
    type: z.literal('DEVICES_IN_TEMPLATE_AREAS'),
  })
    .omit(createOmits)
    .omit({ coordinates: true })
    .required(createRequireds)
    .required({ areaTemplateIds: true })
    .strict(),
]);

const adminBroadcastsContract = c.router(
  {
    create: {
      method: 'POST',
      path: '/admin/broadcasts',
      body: BroadcastInputSchema,
      responses: {
        200: BroadcastSchema,
        400: BadRequestErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:broadcast:create'],
      }),
    },
    list: {
      method: 'GET',
      path: '/admin/broadcasts',
      query: z
        .object({
          limit: z.number().optional(),
          lastEvaluatedKey: z.string().optional(),
          from: z.string().datetime().optional(),
          to: z.string().datetime().optional(),
        })
        .optional(),
      responses: {
        200: z.object({
          items: z.array(BroadcastSchema),
          lastEvaluatedKey: z.string().optional(),
        }),
        400: BadRequestErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:broadcast:read'],
      }),
    },
    get: {
      method: 'GET',
      path: '/admin/broadcasts/:id',
      pathParams: z.object({
        id: z.string(),
      }),
      responses: {
        200: BroadcastSchema,
        404: ApiErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:broadcast:read'],
      }),
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      tags: ['Admin/Broadcasts'],
    }),
  },
);

export { BroadcastSchema, adminBroadcastsContract as adminBroadcastsContract };
