import { Permissions, permissions } from './permissions';

const adminRoles = ['superadmin', 'admin', 'readonly'] as const;
type AdminRole = (typeof adminRoles)[number];

type Role = 'admin.broadcast' | `admin:${AdminRole}`;
type Roles = Role[];

const rolePermissions: Record<Role, Permissions> = {
  'admin.broadcast': [...permissions], // deprecated - remove in next release
  'admin:superadmin': [...permissions],
  'admin:admin': [...permissions],
  'admin:readonly': [
    'admin:analytics:read',
    'admin:broadcast:read',
    'admin:broadcastTemplate:read',
    'admin:logs:read',
    'admin:point:read',
    'admin:settings:feeds:read',
    'admin:settings:rules:read',
    'admin:user:read',
  ],
};

function getRolePermissions(roles: string[]): string[] {
  return roles.flatMap((role) => {
    if (role in rolePermissions) {
      return rolePermissions[role as keyof typeof rolePermissions];
    }

    return [];
  });
}

export type { Role, Roles, AdminRole };
export { adminRoles, getRolePermissions };
