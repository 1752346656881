import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import { UserActions } from './user-actions';
import { tsr } from '../../../utils/tsr';
import { RoleSelect } from './role-select';
import { useCallback } from 'react';
const FRIENDLY_STATUSES = {
    CONFIRMED: 'Active',
    FORCE_CHANGE_PASSWORD: 'Invited',
};
export const UsersList = () => {
    const { data, refetch } = tsr.adminUsers.list.useSuspenseQuery({
        queryKey: ['users'],
    });
    const onUserUpdate = useCallback(async () => {
        await refetch();
    }, []);
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { style: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Email" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Role" }), _jsx(TableCell, { children: "Enabled" }), _jsx(TableCell, { children: "Action" })] }) }), _jsx(TableBody, { children: data.body.map((user) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: user.email }) }), _jsx(TableCell, { children: FRIENDLY_STATUSES[user.status] ?? user.status }), _jsx(TableCell, { children: _jsx(RoleSelect, { user: user, onChange: onUserUpdate }) }), _jsx(TableCell, { children: user.enabled ? 'Yes' : 'No' }), _jsx(TableCell, { children: _jsx(UserActions, { user: user, onChange: onUserUpdate }) })] }, user.id))) })] }) }));
};
