import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { routeMetadata, commonResponses } from './common';
import { FeatureCollectionSchema } from './geojson/FeatureCollectionSchema';

const c = initContract();

const WatchZoneHeatMapSchema = z.object({
  geojson: FeatureCollectionSchema,
  palette: z.array(
    z.object({
      colour: z.string(),
      from: z.number(),
      to: z.number(),
    }),
  ),
  count: z.number(),
});
type WatchZoneHeatMap = z.infer<typeof WatchZoneHeatMapSchema>;

const adminAnalyticsContract = c.router(
  {
    getWatchZoneHeatMap: {
      path: '/admin/analytics/watchZoneHeatMap',
      method: 'GET',
      responses: {
        200: WatchZoneHeatMapSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:analytics:read'],
      }),
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      tags: ['Admin/Analytics'],
    }),
  },
);

export { adminAnalyticsContract };
export type { WatchZoneHeatMap };
