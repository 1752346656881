import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ApiErrorSchema,
  ApiMessageSchema,
  BadRequestErrorSchema,
  commonResponses,
  routeMetadata,
} from './common';

const c = initContract();

const BroadcastTemplateSchema = z.object({
  name: z.string(),
  polygon: z.string(),
  createdByUserId: z.string(),
  createdByUserName: z.string().optional(),
  id: z.string(),
  createdAt: z.number(),
  uppercaseName: z.string(),
});
type BroadcastTemplate = z.infer<typeof BroadcastTemplateSchema>;

const BroadcastTemplateInputSchema = z.object({
  name: z.string(),
  coordinates: z.array(z.array(z.number()).min(2).max(2)).min(3).max(120),
});

const adminBroadcastTemplatesContract = c.router(
  {
    create: {
      method: 'POST',
      path: '/admin/broadcastTemplates',
      body: BroadcastTemplateInputSchema,
      responses: {
        200: BroadcastTemplateSchema,
        400: BadRequestErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:broadcastTemplate:create'],
      }),
    },
    list: {
      method: 'GET',
      path: '/admin/broadcastTemplates',
      query: z
        .object({
          limit: z.number().optional(),
          lastEvaluatedKey: z.string().optional(),
          name: z.string().optional(),
        })
        .optional(),
      responses: {
        200: z.object({
          items: z.array(BroadcastTemplateSchema),
          lastEvaluatedKey: z.string().optional(),
        }),
        400: BadRequestErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:broadcastTemplate:read'],
      }),
    },
    delete: {
      method: 'DELETE',
      path: '/admin/broadcastTemplates/:id',
      pathParams: z.object({
        id: z.string(),
      }),
      body: null,
      responses: {
        200: ApiMessageSchema,
        404: ApiErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:broadcastTemplate:delete'],
      }),
    },
    get: {
      method: 'GET',
      path: '/admin/broadcastTemplates/:id',
      pathParams: z.object({
        id: z.string(),
      }),
      responses: {
        200: BroadcastTemplateSchema,
        404: ApiErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:broadcastTemplate:read'],
      }),
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      tags: ['Admin/Broadcast Templates'],
    }),
  },
);

export type { BroadcastTemplate };

export {
  adminBroadcastTemplatesContract,
  BroadcastTemplateSchema,
  BroadcastTemplateInputSchema,
};
