import { z } from 'zod';

export const HazardTypeSchema = z.enum(['Fire', 'Flood']);
export type HazardType = z.infer<typeof HazardTypeSchema>;

export const StateSchema = z.enum([
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'TAS',
  'SA',
  'VIC',
  'WA',
]);
export type State = z.infer<typeof StateSchema>;

export const AlertLevelSchema = z.enum([
  'Emergency',
  'WatchAct',
  'Advice',
  'Incident',
  'AllClear',
  'PlannedBurn',
]);

export type AlertLevel = z.infer<typeof AlertLevelSchema>;

export const PositionSchema = z
  .array(z.number())
  .describe('X, Y, and (optionally) Z');
export type Position = z.infer<typeof PositionSchema>;

export const PointSchema = z.object({
  type: z.literal('Point'),
  coordinates: PositionSchema,
});
export type Point = z.infer<typeof PointSchema>;

export const PolygonSchema = z.object({
  type: z.literal('Polygon'),
  coordinates: z.array(z.array(PositionSchema)),
});
export type Polygon = z.infer<typeof PolygonSchema>;

export const PinSchema = z.object({
  /**
   * The point of the pin.
   */
  point: PointSchema,

  /**
   * An optional description of the pin.
   */
  description: z.string().optional(),

  /**
   * The alert level of the pin.
   */
  alertLevel: AlertLevelSchema,

  /**
   * Only used on for notification.
   */
  notify: z.boolean().optional(),

  /**
   * Only used on for notification.
   */
  isEscalation: z.boolean().optional(),
});
export type Pin = z.infer<typeof PinSchema>;

export const AreaSchema = z.object({
  /**
   * The areas Polygons/Points
   */
  polygons: z.array(PolygonSchema),

  /**
   * An optional description of the area.
   */
  description: z.string().optional(),

  /**
   * The alert level of the area. If undefined, notifications will not be sent for the area.
   */
  alertLevel: AlertLevelSchema.optional(),

  /**
   * Only used on for notification.
   */
  notify: z.boolean().optional(),

  /**
   * Only used on for notification.
   */
  isEscalation: z.boolean().optional(),
});
export type Area = z.infer<typeof AreaSchema>;

export const IncidentSchema = z.object({
  /**
   * The original alert identifier. Will resolve references if available.
   * This identifier must not change when the Alert is updated.
   */
  identifier: z.string(),

  /** The date that the alert was sent. */
  sent: z.string(),

  /** The date the incident was last updated with a major update.
   * Used to determine if users should be (re)notified about any updates.
   */
  lastMajorUpdate: z.string().optional(),

  /** The date that the next update will be provided. */
  nextUpdate: z.string().optional(),

  /** The name of the sender. */
  senderName: z.string().optional(),

  status: z.string().optional(),

  headline: z.string().optional(),

  /** Call to action. */
  action: z.string().optional(),

  /** deprecated; replaced by descriptionHtml */
  description: z.string().optional(),

  /** A description of the alert that supports both plain text or HTML. */
  descriptionHtml: z.string().optional(),

  /** deprecated; replaced by instructionHtml */
  instruction: z.string().optional(),

  /** Supports both plain text and html */
  instructionHtml: z.string().optional(),

  /** Applies a "More Information" button to be rendered in a web browser */
  html: z.string().optional(),

  link: z.string().optional(),

  hazardType: HazardTypeSchema,

  incidentType: z.string().optional(),

  /** The location of the incident */
  location: z.string().optional(),

  /**
   * The council area where the incident is located.
   */
  councilArea: z.string().optional(),

  /**
   * The state where the incident is located.
   */
  state: StateSchema,

  /**
   * The incident status.
   */
  incidentStatus: z.string().optional(),

  /**
   * The Pin.
   */
  pin: PinSchema,

  /**
   * An array of areas of interest.
   */
  area: z.array(AreaSchema).optional(),

  fire: z.string().optional(),

  size: z.string().optional(),

  responsibleAgency: z.string().optional(),

  /**
   * Only used on for notification.
   */
  isNewUpdate: z.boolean().optional(),
});

export type Incident = z.infer<typeof IncidentSchema>;
