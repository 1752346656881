import { ZodErrorSchema } from '@ts-rest/core';
import { z } from 'zod';
import { ApiErrorSchema } from './ApiErrorSchema';

export const BadRequestSchemaGeneric = ApiErrorSchema.extend({
  name: z.literal('BadRequestError'),
});

// generated by ts-rest + Zod
export const RequestValidationErrorSchema = BadRequestSchemaGeneric.extend({
  message: z.literal('Request validation failed'),
  pathParameterErrors: ZodErrorSchema.nullable(),
  headerErrors: ZodErrorSchema.nullable(),
  queryParameterErrors: ZodErrorSchema.nullable(),
  bodyErrors: ZodErrorSchema.nullable(),
});

export const BadRequestErrorSchema = z.union([
  RequestValidationErrorSchema,
  BadRequestSchemaGeneric,
]);
