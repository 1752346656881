import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Container } from '../../components/container';
import { UsersList } from './components/users-list';
import { LoadingView } from '../../components/loading-view';
import { useHasPermission } from '../../core/auth/hooks/use-has-permission';
export const Users = () => {
    const canCreateUser = useHasPermission('admin:user:create');
    return (_jsx(Container, { children: _jsxs(Stack, { paddingY: 4, spacing: 3, children: [_jsx(Box, { children: _jsx(Button, { variant: "contained", startIcon: _jsx(Add, {}), component: Link, to: "invite", disabled: !canCreateUser, children: "Invite user" }) }), _jsx(Typography, { variant: "h5", children: "Users" }), _jsx(Suspense, { fallback: _jsx(LoadingView, { height: "100%" }), children: _jsx(UsersList, {}) })] }) }));
};
