import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  AlertLevelSchema,
  ApiErrorSchema,
  ApiMessageSchema,
  commonResponses,
  IncidentSchema,
  routeMetadata,
} from './common';

const c = initContract();

const NotificationSchema = z.object({
  notificationHash: z.string(),
  messageTitle: z.string().optional(),
  messageText: z.string(),
  type: z.string(),
  incident: IncidentSchema.optional(),
  alertLevel: AlertLevelSchema.optional(),
  regionName: z.string().optional(),
  dangerLevel: z.string().optional(),
  moreInformationUrl: z.string().optional(),
  userIdDeviceToken: z.string(),
  createdAt: z.number(),
  fireAreaId: z.string().optional(),
});

const notificationsContract = c.router(
  {
    list: {
      method: 'GET',
      path: '/devices/:deviceToken/notifications',
      pathParams: z.object({
        deviceToken: z.string(),
      }),
      responses: {
        200: z.array(NotificationSchema),
      },
    },
    get: {
      method: 'GET',
      path: '/devices/:deviceToken/notifications/:notificationHash',
      pathParams: z.object({
        deviceToken: z.string(),
        notificationHash: z.string(),
      }),
      responses: {
        200: NotificationSchema,
        404: ApiErrorSchema,
      },
    },
    delete: {
      method: 'DELETE',
      path: '/devices/:deviceToken/notifications/:notificationHash',
      pathParams: z.object({
        deviceToken: z.string(),
        notificationHash: z.string(),
      }),
      body: null,
      responses: {
        200: ApiMessageSchema,
      },
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({ tags: ['Notifications'] }),
  },
);

export { notificationsContract };
