import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
  ApiMessageSchema,
  commonResponses,
  IncidentCollectionSchema,
  IncidentSchema,
  routeMetadata,
} from './common';

const c = initContract();

const debugContract = c.router(
  {
    onIncident: {
      path: '/debug/onIncident',
      method: 'POST',
      body: z.object({
        incident: IncidentSchema,
      }),
      responses: {
        200: ApiMessageSchema,
      },
    },
    onIngestFeed: {
      path: '/debug/onIngestFeed',
      method: 'POST',
      body: z.object({
        queueNotification: z.boolean(),
      }),
      responses: {
        200: z.object({
          success: z.boolean(),
          message: z.string(),
        }),
      },
    },
    onIngestNswFireBanFeed: {
      path: '/debug/onIngestNswFireBanFeed',
      method: 'POST',
      body: null,
      responses: {
        200: z.object({
          success: z.boolean(),
          message: z.string(),
        }),
      },
    },
    processFeed: {
      path: '/debug/processFeed',
      method: 'POST',
      body: z.object({
        feed: IncidentCollectionSchema,
        key: z.string(),
        publish: z.boolean().optional(),
      }),
      responses: {
        200: z.object({
          success: z.boolean(),
          published: z.boolean(),
          incidents: z.array(IncidentSchema),
        }),
      },
    },
    trigger500: {
      path: '/debug/trigger500',
      method: 'GET',
      responses: {},
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      roles: ['admin:admin'],
      tags: ['Debug'],
    }),
  },
);

export { debugContract };
