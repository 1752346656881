import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useSnackbar } from '../../../components/snackbar';
import { tsr } from '../../../utils/tsr';
import { useHasPermission } from '../../../core/auth/hooks/use-has-permission';
export const UserActions = ({ user, onChange }) => {
    const canUpdateUser = useHasPermission('admin:user:update');
    const { mutateAsync: deactivateUser } = tsr.adminUsers.deactivate.useMutation();
    const { mutateAsync: reactivateUser } = tsr.adminUsers.reactivate.useMutation();
    const { mutateAsync: resendUserInvitation } = tsr.adminUsers.resendInvitation.useMutation();
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const deactivate = useCallback(async () => {
        setLoading(true);
        try {
            await deactivateUser({ params: { id: user.id } });
            await onChange();
            showSnackbar(`${user.email} has been deactivated`);
        }
        finally {
            setLoading(false);
        }
    }, [onChange, showSnackbar, user]);
    const reactivate = useCallback(async () => {
        setLoading(true);
        try {
            await reactivateUser({ params: { id: user.id } });
            await onChange();
            showSnackbar(`${user.email} has been reactivated`);
        }
        finally {
            setLoading(false);
        }
    }, [onChange, showSnackbar, user]);
    const resendInvitation = useCallback(async () => {
        setLoading(true);
        try {
            await resendUserInvitation({ params: { id: user.id } });
            await onChange();
            showSnackbar(`Invitation has been resent to ${user.email}`);
        }
        finally {
            setLoading(false);
        }
    }, [onChange, showSnackbar, user]);
    if (loading) {
        return _jsx(CircularProgress, { size: 15, style: { marginLeft: 24 } });
    }
    return (_jsxs(_Fragment, { children: [user.enabled && user.status === 'FORCE_CHANGE_PASSWORD' && (_jsx(Button, { onClick: resendInvitation, disabled: !canUpdateUser, children: "Resend invitation" })), _jsx(Button, { onClick: () => (user.enabled ? deactivate() : reactivate()), disabled: (user.enabled && user.role === 'superadmin') || !canUpdateUser, children: user.enabled ? 'Deactivate' : 'Reactivate' })] }));
};
