import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
  AdminRole,
  adminRoles,
  ApiMessageSchema,
  commonResponses,
  routeMetadata,
} from './common';

const c = initContract();

const AdminUserSchema = z.object({
  id: z.string(),
  enabled: z.boolean(),
  status: z.string(),
  email: z.string(),
  role: z.enum(adminRoles),
});

type AdminUser = z.infer<typeof AdminUserSchema>;

const configurableRoles = adminRoles.filter((v) => v !== 'superadmin') as [
  AdminRole,
  ...AdminRole[],
];

const adminUsersContract = c.router(
  {
    list: {
      path: '/admin/users',
      method: 'GET',
      responses: {
        200: z.array(AdminUserSchema),
        ...commonResponses,
      },
      metadata: routeMetadata({
        permissions: ['admin:user:read'],
      }),
    },
    create: {
      path: '/admin/users',
      method: 'POST',
      body: z.object({
        email: z.string().email(),
        role: z.enum(configurableRoles),
      }),
      responses: {
        200: ApiMessageSchema,
        ...commonResponses,
      },
      metadata: routeMetadata({
        permissions: ['admin:user:create'],
      }),
    },
    me: {
      method: 'GET',
      path: '/admin/users/me',
      responses: {
        200: z.object({
          userId: z.string(),
          email: z.string(),
          roles: z.array(z.string()).optional(),
        }),
        ...commonResponses,
      },
    },
    update: {
      path: '/admin/users/:id',
      pathParams: z.object({
        id: z.string(),
      }),
      method: 'PATCH',
      body: z.object({
        role: z.enum(configurableRoles),
      }),
      responses: {
        200: ApiMessageSchema,
        ...commonResponses,
      },
      metadata: routeMetadata({
        permissions: ['admin:user:update'],
      }),
    },
    deactivate: {
      path: '/admin/users/:id/deactivate',
      pathParams: z.object({
        id: z.string(),
      }),
      method: 'PATCH',
      body: null,
      responses: {
        200: ApiMessageSchema,
        ...commonResponses,
      },
      metadata: routeMetadata({
        permissions: ['admin:user:update'],
      }),
    },
    reactivate: {
      path: '/admin/users/:id/reactivate',
      pathParams: z.object({
        id: z.string(),
      }),
      method: 'PATCH',
      body: null,
      responses: {
        200: ApiMessageSchema,
        ...commonResponses,
      },
      metadata: routeMetadata({
        permissions: ['admin:user:update'],
      }),
    },
    resendInvitation: {
      path: '/admin/users/:id/resendInvitation',
      pathParams: z.object({
        id: z.string(),
      }),
      method: 'PATCH',
      body: null,
      responses: {
        200: ApiMessageSchema,
        ...commonResponses,
      },
      metadata: routeMetadata({
        permissions: ['admin:user:update'],
      }),
    },
    login: {
      method: 'POST',
      path: '/admin/users/login',
      body: z.object({
        code: z.string(),
        redirect: z.string(),
      }),
      responses: {
        200: z.object({
          token: z.string(),
        }),
        403: commonResponses[403],
        500: commonResponses[500],
      },
      summary: 'Login Admin Dashboard user',
      metadata: routeMetadata({
        anonymous: true,
      }),
    },
  },
  { metadata: routeMetadata({ tags: ['Admin/Users'] }) },
);

export { adminUsersContract };
export type { AdminUser };
