import { initContract } from '@ts-rest/core';
import {
  ApiMessageSchema,
  BadRequestErrorSchema,
  BadRequestSchemaGeneric,
  commonResponses,
  routeMetadata,
} from './common';
import { z } from 'zod';

const c = initContract();

const JurisdictionSchema = z.enum([
  'act',
  'nsw',
  'nt',
  'qld',
  'sa',
  'tas',
  'vic',
  'wa',
]);
const FeedTypeSchema = z.enum(['fires', 'floods', 'toban']);
type FeedType = z.infer<typeof FeedTypeSchema>;
const FeedSourceSchema = z.enum(['live', 'mock', 'custom']);
type FeedSource = z.infer<typeof FeedSourceSchema>;

const FeedSettingSchema = z.object({
  jurisdiction: JurisdictionSchema,
  type: FeedTypeSchema,
  feedSettings: z.record(z.string(), z.string()),
  source: FeedSourceSchema,
});
type FeedSetting = z.infer<typeof FeedSettingSchema>;

const FeedSettingInputSchema = FeedSettingSchema.omit({
  feedSettings: true,
}).extend({
  custom: z.record(z.string(), z.string()).optional(),
});
type FeedSettingInput = z.infer<typeof FeedSettingInputSchema>;

const adminMockFeedSettingsContract = c.router(
  {
    get: {
      path: '/admin/settings/feeds',
      method: 'GET',
      responses: {
        200: z.array(FeedSettingSchema),
      },
      metadata: routeMetadata({
        permissions: ['admin:settings:feeds:read'],
      }),
    },
    update: {
      path: '/admin/settings/feeds',
      method: 'PATCH',
      body: z.object({ feedSettings: z.array(FeedSettingInputSchema) }),
      responses: {
        200: ApiMessageSchema,
        400: z.union([
          BadRequestSchemaGeneric.extend({
            validationErrors: z.array(
              z.object({
                keyword: z.string(),
                instancePath: z.string(),
                schemaPath: z.string(),
                params: z.record(z.string(), z.any()),
                propertyName: z.string().optional(),
                message: z.string().optional(),
              }),
            ),
          }),
          BadRequestErrorSchema,
        ]),
      },
      metadata: routeMetadata({
        permissions: ['admin:settings:feeds:update'],
      }),
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      tags: ['Admin/Feed Settings'],
    }),
  },
);

export { adminMockFeedSettingsContract };
export type { FeedType, FeedSource, FeedSetting, FeedSettingInput };
