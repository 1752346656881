import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  BadRequestErrorSchema,
  commonResponses,
  routeMetadata,
} from './common';

const c = initContract();

const adminPointsContract = c.router(
  {
    count: {
      method: 'POST',
      path: '/admin/points/count',
      body: z.object({
        coordinates: z.array(z.array(z.number()).min(2).max(2)).max(120),
      }),
      query: z
        .object({
          maxCount: z.number().optional(),
        })
        .optional(),
      responses: {
        200: z.object({
          count: z.number(),
        }),
        400: BadRequestErrorSchema,
      },
      metadata: routeMetadata({
        permissions: ['admin:point:read'],
      }),
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      tags: ['Admin/Points'],
    }),
  },
);

export { adminPointsContract };
