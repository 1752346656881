import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { adminContracts } from '@hazards/api-contract';
import { isErrorResponse } from '@ts-rest/core';
import { initTsrReactQuery, isFetchError, } from '@ts-rest/react-query/v5';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getLocalAccessToken } from '../core/auth/local-token-utils';
import { useAuth } from '../core/auth';
import { getApiEndpoint } from './fallback';
import { useSnackbar } from '../components/snackbar';
export const tsr = initTsrReactQuery(adminContracts, {
    baseUrl: getApiEndpoint(),
    baseHeaders: {
        Authorization: () => {
            const accessToken = getLocalAccessToken();
            return accessToken ? `Bearer ${accessToken}` : '';
        },
    },
});
export function TsrQueryClientProvider({ children }) {
    const { signOut } = useAuth();
    const { showSnackbar } = useSnackbar();
    const queryClient = useMemo(() => new QueryClient({
        defaultOptions: {
            queries: {
                retry: (failureCount, error) => {
                    // Don't retry on auth errors
                    if (isErrorResponse(error) &&
                        (error.status === 401 || error.status === 403)) {
                        if (error.status === 401) {
                            signOut(queryClient);
                        }
                        return false;
                    }
                    // Retry up to 3 times on other errors
                    return failureCount < 3;
                },
            },
            mutations: {
                onError: (error) => {
                    if (isErrorResponse(error)) {
                        if (error.status === 401) {
                            signOut(queryClient);
                        }
                        if (error.status === 403) {
                            showSnackbar(error.body.message, 'error');
                        }
                    }
                },
            },
        },
    }), [signOut]);
    return (_jsx(QueryClientProvider, { client: queryClient, children: _jsx(tsr.ReactQueryProvider, { children: children }) }));
}
export function getTsrErrorMessage(error) {
    if (isFetchError(error)) {
        return 'Failed to connect to server. Please check your internet connection.';
    }
    if (error?.body?.message) {
        return error.body.message;
    }
    return 'Unexpected error occurred.';
}
