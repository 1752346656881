import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { tsr } from '../../../utils/tsr';
export const ButtonContainer = ({ children }) => (_jsx(Box, { display: "flex", justifyContent: "flex-end", flexGrow: 1, children: _jsx(Box, { alignSelf: "flex-end", children: children }) }));
export const useBroadcastTemplates = () => {
    const { data, isLoading } = tsr.adminBroadcastTemplates.list.useSuspenseQuery({
        queryData: {
            query: {},
        },
        queryKey: ['broadcastTemplates'],
    });
    return {
        templates: data.body?.items,
        isLoading,
    };
};
// TODO improvement: filter server side using broadcastRepository.findBroadcastTemplateByIds
export const useFilteredBroadcastTemplates = (templateIds) => {
    const { templates, isLoading } = useBroadcastTemplates();
    const filteredTemplates = useMemo(() => {
        if (!templates || !templateIds?.length) {
            return undefined;
        }
        return templates.filter((template) => templateIds.includes(template.id));
    }, [templateIds, templates]);
    return {
        isLoading,
        templates: filteredTemplates,
    };
};
export const useSelectedBroadcastTemplates = ({ control, templates = [], }) => {
    const watchBroadcastTemplateIds = useWatch({
        control,
        name: 'broadcastTemplateIds',
    });
    return useMemo(() => watchBroadcastTemplateIds.map((id) => {
        const template = templates.find((template) => template.id === id);
        if (typeof template === 'undefined') {
            throw new Error(`Selected Broadcast Template with id "${id}" not found.`);
        }
        return template;
    }), [watchBroadcastTemplateIds, templates]);
};
