import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { IngestRuleTypeEnum, } from '@hazards/api-contract';
import { TableCell1, TableCell2, TableCell3, TableCell4, TableCell5, } from './common';
const ingestRuleType = Object.values(IngestRuleTypeEnum);
export function isIngestRulePresentation(value) {
    return ingestRuleType.includes(value?.type);
}
/** Handles either a IngestRule or a nested IngestRuleMap */
export function IngestRuleNested({ data, path = '', }) {
    if (typeof data === 'undefined' || isIngestRulePresentation(data)) {
        return _jsx(IngestRule, { id: path, data: data }, path);
    }
    return (_jsx(_Fragment, { children: Object.entries(data)
            .sort(sortRules)
            .map(([key, value]) => {
            const newPath = path ? path.concat(`.${key}`) : key;
            return _jsx(IngestRuleNested, { path: newPath, data: value }, newPath);
        }) }));
}
function sortRules([a], [b]) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}
export function IngestRule({ id, data, }) {
    if (!data) {
        return (_jsxs(TableRow, { children: [_jsx(TableCell1, { children: id }), _jsx(TableCell2, { children: "n/a" }), _jsx(TableCell3, { children: "n/a" }), _jsx(TableCell4, { children: "n/a" }), _jsx(TableCell5, { children: "n/a" })] }));
    }
    if (data.type === 'xpath' || data.type === 'jsonpath') {
        return (_jsxs(TableRow, { children: [_jsx(TableCell1, { children: id }), _jsx(TableCell2, { children: data.type }), _jsx(TableCell3, { children: data.path }), _jsx(TableCell4, { children: data.transforms && (_jsx(TransformDefinitions, { transforms: data.transforms })) }), _jsx(TableCell5, { children: data.valueType })] }));
    }
    if (data.type === 'constant') {
        return (_jsxs(TableRow, { children: [_jsx(TableCell1, { children: id }), _jsx(TableCell2, { children: "constant" }), _jsx(TableCell3, { children: data.constant }), _jsx(TableCell4, { children: data.transforms && (_jsx(TransformDefinitions, { transforms: data.transforms })) }), _jsx(TableCell5, { children: data.valueType })] }));
    }
    if (data.type === 'join') {
        return (_jsxs(TableRow, { children: [_jsx(TableCell1, { children: id }), _jsx(TableCell2, { children: "join" }), _jsx(TableCell3, { children: data.rules }), _jsx(TableCell4, { children: data.transforms && (_jsx(TransformDefinitions, { transforms: data.transforms })) }), _jsx(TableCell5, { children: data.valueType })] }));
    }
    if (data.type === 'reference' || data.type === 'custom') {
        return (_jsxs(TableRow, { children: [_jsx(TableCell1, { children: id }), _jsx(TableCell2, { children: data.type }), _jsx(TableCell3, { children: data.definition }), _jsx(TableCell4, { children: data.transforms && (_jsx(TransformDefinitions, { transforms: data.transforms })) }), _jsx(TableCell5, { children: data.valueType })] }));
    }
}
function TransformDefinitions({ transforms, }) {
    if (transforms.length > 1) {
        return (_jsx("ul", { children: transforms.map((transform, index) => (_jsx("li", { children: _jsx(TransformDefinition, { data: transform }) }, `transform-${index + 1}`))) }));
    }
    return _jsx(TransformDefinition, { data: transforms[0] });
}
function TransformDefinition({ data }) {
    if (data.type === 'mapToAlertLevel') {
        const [definition, dictStr] = data.definition.split('\n');
        const dict = JSON.parse(dictStr);
        return (_jsxs("div", { children: [definition, _jsx("br", {}), _jsx(TableContainer, { style: { padding: '20px' }, children: _jsxs(Table, { style: { border: '1px solid #CCC' }, children: [_jsx(TableHead, { children: _jsxs(TableRow, { style: { backgroundColor: '#FAFAFA' }, children: [_jsx(TableCell, { children: "Feed Value" }), _jsx(TableCell, { children: "Alert Level" })] }) }), _jsx(TableBody, { children: Object.entries(dict).map(([key, value]) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: key }), _jsx(TableCell, { children: value })] }, key))) })] }) })] }));
    }
    return _jsx("div", { children: data.definition });
}
