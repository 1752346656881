import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ApiErrorSchema,
  ApiMessageSchema,
  commonResponses,
  DeviceTypeSchema,
  routeMetadata,
} from './common';

const c = initContract();

const AuthResponseSchema = z.object({
  token: z.string(),
});

const usersContract = c.router(
  {
    create: {
      method: 'POST',
      path: '/users',
      body: null,
      responses: {
        200: AuthResponseSchema,
        500: commonResponses[500],
      },
      summary: 'Create a new anonymous user',
      metadata: routeMetadata({
        anonymous: true,
      }),
    },
    me: {
      method: 'GET',
      path: '/users/me',
      responses: {
        200: z.object({
          userId: z.string(),
          email: z.string(),
        }),
        ...commonResponses,
      },
      summary: 'Return user details',
    },
    login: {
      method: 'POST',
      path: '/users/login',
      body: z.object({
        code: z.string(),
        redirect: z.string(),
        deviceToken: z.string().nullish(),
        deviceType: DeviceTypeSchema.nullish(),
      }),
      responses: {
        200: AuthResponseSchema,
        ...commonResponses,
      },
      summary: 'Attempt to log user in',
    },
    loginV2: {
      method: 'POST',
      path: '/v2/users/login',
      body: z.object({
        email: z.string(),
        password: z.string(),
        deviceToken: z.string(),
        deviceType: DeviceTypeSchema,
      }),
      responses: {
        200: AuthResponseSchema,
        400: ApiErrorSchema,
        ...commonResponses,
      },
      summary: 'Attempt to log user in',
    },
    signup: {
      method: 'POST',
      path: '/users/signup',
      body: z.object({
        email: z.string(),
        password: z.string(),
      }),
      responses: {
        200: ApiMessageSchema,
        ...commonResponses,
      },
    },
    confirmSignup: {
      method: 'POST',
      path: '/users/confirmSignup',
      body: z.object({
        email: z.string(),
        code: z.string(),
      }),
      responses: {
        200: ApiMessageSchema,
        400: ApiErrorSchema,
        ...commonResponses,
      },
    },
    initiateResetPassword: {
      method: 'POST',
      path: '/users/initiateResetPassword',
      body: z.object({
        email: z.string(),
      }),
      responses: {
        200: ApiMessageSchema,
        404: ApiErrorSchema,
        ...commonResponses,
      },
    },
    resetPassword: {
      method: 'POST',
      path: '/users/resetPassword',
      body: z.object({
        email: z.string(),
        code: z.string(),
        password: z.string(),
      }),
      responses: {
        200: ApiMessageSchema,
        404: ApiErrorSchema,
        ...commonResponses,
      },
    },
    migrated: {
      method: 'POST',
      path: '/users/migrated',
      body: z.object({
        deviceId: z.string(),
        deviceToken: z.string(),
      }),
      responses: {
        200: z.object({
          token: z.string(),
          isAnonymous: z.boolean(),
        }),
        404: ApiErrorSchema,
        500: commonResponses[500],
      },
      metadata: routeMetadata({
        anonymous: true,
      }),
    },
    logout: {
      method: 'POST',
      path: '/users/logout',
      body: z.object({
        deviceToken: z.string(),
        deviceType: DeviceTypeSchema,
      }),
      responses: {
        200: AuthResponseSchema,
        ...commonResponses,
      },
      summary: 'Log the user out',
    },
    delete: {
      method: 'DELETE',
      path: '/users',
      summary: 'Delete a user',
      body: z.object({
        deviceToken: z.string(),
        deviceType: DeviceTypeSchema,
      }),
      responses: {
        200: AuthResponseSchema,
        ...commonResponses,
      },
    },
  },
  { metadata: routeMetadata({ tags: ['Users'] }) },
);

export { usersContract };
