import { useMemo } from 'react';
import { getRolePermissions } from '@hazards/api-contract';
import { tsr } from '../../../utils/tsr';
const CACHE_TIME = 30 * 60 * 1000; // 30 minutes to match token expiry
export function useUserDetails() {
    const { data } = tsr.adminUsers.me.useSuspenseQuery({
        queryKey: ['users', 'me'],
        staleTime: CACHE_TIME,
        gcTime: CACHE_TIME,
    });
    const permissions = useMemo(() => {
        return getRolePermissions(data.body.roles ?? []);
    }, [data.body.roles]);
    return { ...data.body, permissions };
}
