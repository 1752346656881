import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
import { Container } from '../../components/container';
import { Stack, Typography } from '@mui/material';
import { LoadingView } from '../../components/loading-view';
import { WatchZoneHeatMap } from './components/watch-zone-heat-map';
import { HeatPalette } from './components/heat-palette';
import { tsr } from '../../utils/tsr';
export const Analytics = () => {
    const { data, isLoading } = tsr.adminAnalytics.getWatchZoneHeatMap.useQuery({
        queryKey: ['analytics', 'watchZoneHeatMap'],
        throwOnError: true,
    });
    return (_jsx(Container, { children: _jsxs(Stack, { paddingY: 4, spacing: 3, children: [_jsx(Typography, { variant: "h5", children: "Watch Zone Heat Map" }), isLoading && _jsx(LoadingView, { height: "100%" }), data && (_jsxs("div", { className: css({ display: 'flex' }), children: [_jsx(WatchZoneHeatMap, { geojson: data.body.geojson }), _jsx(HeatPalette, { palette: data.body.palette, total: data.body.count })] }))] }) }));
};
