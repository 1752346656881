import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { ApiErrorSchema, commonResponses, routeMetadata } from './common';

const c = initContract();

enum FireDangerRatingEnum {
  NONE = 'NONE',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
  EXTREME = 'EXTREME',
  CATASTROPHIC = 'CATASTROPHIC',
}

const FdrDistrictSchema = z.object({
  regionName: z.string(),
  fireAreaId: z.string(),
  councils: z.string().optional(),
  fireBanToday: z.enum(['Yes', 'No']),
  dangerLevelToday: z.nativeEnum(FireDangerRatingEnum),
  updatedAtLocalTime: z.string(),
});

type FdrDistrict = z.infer<typeof FdrDistrictSchema>;

const fdrsContract = c.router(
  {
    list: {
      method: 'GET',
      path: '/fdrs',
      query: z
        .object({
          polygon: z.array(z.tuple([z.number(), z.number()])).optional(),
          point: z
            .tuple([z.number(), z.number()])
            .optional()
            .describe('Point in [longitude, latitude] format.'),
        })
        .optional(),
      responses: {
        200: z.array(FdrDistrictSchema),
        404: ApiErrorSchema,
      },
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({ tags: ['FDRs'] }),
  },
);

export type { FdrDistrict };
export { FireDangerRatingEnum, fdrsContract };
