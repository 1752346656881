import z from 'zod';

const BBoxSchema = z.union([
  z.tuple([z.number(), z.number(), z.number(), z.number()]),
  z.tuple([
    z.number(),
    z.number(),
    z.number(),
    z.number(),
    z.number(),
    z.number(),
  ]),
]);

const GeoJsonObject = z.object({
  type: z.string(),
  bbox: BBoxSchema.optional(),
});

const PositionSchema = z.array(z.number());
const GeometrySchema = GeoJsonObject.extend({
  coordinates: z.union([
    PositionSchema,
    z.array(PositionSchema),
    z.array(z.array(PositionSchema)),
    z.array(z.array(z.array(PositionSchema))),
  ]),
});

const PointSchema = GeoJsonObject.extend({
  type: z.literal('Point'),
  coordinates: PositionSchema,
});
const LineStringSchema = GeoJsonObject.extend({
  type: z.literal('LineString'),
  coordinates: z.array(PositionSchema),
});
const PolygonSchema = GeoJsonObject.extend({
  type: z.literal('Polygon'),
  coordinates: z.array(z.array(PositionSchema)),
});
const MultiPointSchema = GeoJsonObject.extend({
  type: z.literal('MultiPoint'),
  coordinates: z.array(PositionSchema),
});
const MultiLineStringSchema = GeoJsonObject.extend({
  type: z.literal('MultiLineString'),
  coordinates: z.array(z.array(PositionSchema)),
});
const MultiPolygonSchema = GeoJsonObject.extend({
  type: z.literal('MultiPolygon'),
  coordinates: z.array(z.array(z.array(PositionSchema))),
});

const GeometryCollectionSchema = GeoJsonObject.extend({
  type: z.literal('GeometryCollection'),
  geometries: z.array(
    z.union([
      PointSchema,
      LineStringSchema,
      PolygonSchema,
      MultiPointSchema,
      MultiLineStringSchema,
      MultiPolygonSchema,
    ]),
  ),
});

const FeatureSchema = GeoJsonObject.extend({
  type: z.literal('Feature'),
  geometry: z.union([GeometrySchema, GeometryCollectionSchema]),
  id: z.union([z.string(), z.number()]).optional(),
  properties: z.record(z.string(), z.any()).nullable(),
});

const FeatureCollectionSchema = GeoJsonObject.extend({
  type: z.literal('FeatureCollection'),
  features: z.array(FeatureSchema),
});

type FeatureCollection = z.infer<typeof FeatureCollectionSchema>;

export { FeatureCollectionSchema };
export type { FeatureCollection };
